<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF aC">
			<a-icon type="arrow-left" @click="$router.go(-1)" />
		</div>
		<div>
			<a-tabs size="large" default-active-key="1">
				<div slot="tabBarExtraContent" class="dF" style="gap: 20px">
					<div class="dF" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV" href="javascript:;" class="text-primary">CSV</a>
					</div>
				</div>
				<a-tab-pane key="1" tab="Sales Details">
					<ChartCard :details="selectedChart" :loading="loading" />
				</a-tab-pane>
			</a-tabs>
			<a-card style="margin-top: 50px" class="table-card">
				<a-table class="tableStyle" :columns="columns" :data-source="dataRecords" :row-key="(record) => record.id"
					:scroll="{ x: 1200 }">
					<template #offerDate="offerDate">
						{{ formatDate(offerDate) }}
					</template>
					<template #closingDate="closingDate">
						{{ formatDate(closingDate) }}
					</template>
					<template #purchaseDate="purchaseDate">
						{{ formatDate(purchaseDate) }}
					</template>
					<template #pricePerSqFt="pricePerSqFt">
						<span v-if="pricePerSqFt">${{ pricePerSqFt }}</span>
					</template>
					<template #purchasePrice="purchasePrice">
						<span v-if="purchasePrice">${{ purchasePrice.toLocaleString("US") }}</span>
					</template>
				</a-table>
			</a-card>
		</div>
	</div>
</template>

<script>
import ChartCard from "@/components/charts/chartCard";
import { mapState } from "vuex";
import moment from "moment";
import { formatDate } from 'bh-mod'

export default {
	components: {
		ChartCard,
	},
	data: () => ({
		loading: false,
		error: false,
		salesReport: {
			currentData: {},
		},
	}),

	computed: {
		...mapState(["selectedChart", "records", "instance"]),

		productType() {
			return this.instance.productType || "lowrise";
		},

		corporateInstance() {
			return (this.instance && this.instance.projectType === 'corporate')
		},

		selectedProjects() {
			return this.$store.state.selectedProjects
		},

		dataRecords() {
			let records = this.records.sales;
			if (this.corporateInstance) {
				records = records.filter(r => this.selectedProjects.includes(r.instanceName))
			}

			return records
		},

		columns() {
			const columns = [
				{
					title: "Project",
					dataIndex: "instanceName",
					key: "instanceName",
					sorter: (a, b) =>
						a.instanceName < b.instanceName ? -1 : a.instanceName > b.instanceName ? 1 : 0,
				}
			];
			if (this.productType === "lowrise") {
				columns.push(
					...[
						{
							title: "Lot #",
							dataIndex: "lot",
							key: "lot",
							sorter: (a, b) =>
								((a.lot && Number(a.lot)) - (b.lot && Number(b.lot))),
						},
						{
							title: "Model Name",
							dataIndex: "modelName",
							key: "modelName",
							sorter: (a, b) => {
								const aa =
									(a.modelName &&
										a.modelName.toLowerCase()) ||
									"";
								const bb =
									(b.modelName &&
										b.modelName.toLowerCase()) ||
									"";
								return aa < bb ? -1 : aa > bb ? 1 : 0;
							},
						},
						{
							title: "Product type",
							dataIndex: "productType",
							key: "productType",
							sorter: (a, b) =>
								a.productType < b.productType
									? -1
									: a.productType > b.productType
										? 1
										: 0,
						},
						{
							title: "Premium(s)",
							dataIndex: "premiums",
							key: "premiums",
							sorter: (a, b) =>
								a.premiums < b.premiums
									? -1
									: a.premiums > b.premiums
										? 1
										: 0,
						},
					]
				);
			} else if (this.productType === "highrise") {
				columns.push(
					...[
						{
							title: "Unit #",
							dataIndex: "unit",
							key: "unit",
							sorter: (a, b) => a.unit - b.unit,
						},
						{
							title: "Unit Name",
							dataIndex: "unitName",
							key: "unitName",
							sorter: (a, b) =>
								a.unitName < b.unitName
									? -1
									: a.unitName > b.unitName
										? 1
										: 0,
						},
						{
							title: "Unit type",
							dataIndex: "unitType",
							key: "unitType",
							sorter: (a, b) =>
								a.unitType < b.unitType
									? -1
									: a.unitType > b.unitType
										? 1
										: 0,
						},
						{
							title: "Premium(s)",
							dataIndex: "premiums",
							key: "premiums",
							sorter: (a, b) =>
								a.premiums < b.premiums
									? -1
									: a.premiums > b.premiums
										? 1
										: 0,
						},
						{
							title: "Add on(s)",
							dataIndex: "addOns",
							key: "addOns",
							sorter: (a, b) =>
								a.addOns < b.addOns
									? -1
									: a.addOns > b.addOns
										? 1
										: 0,
						},
					]
				);
			}
			columns.push(
				...[
					{
						title: "Offer Date",
						dataIndex: "offerDate",
						key: "offerDate",
						scopedSlots: { customRender: "offerDate" },
						sorter: (a, b) => ((a.offerDate || 0) - (b.offerDate || 0)),
					},
					{
						title: "Closing Date",
						dataIndex: "closingDate",
						key: "closingDate",
						scopedSlots: { customRender: "closingDate" },
						sorter: (a, b) =>
							(a.closingDate ? moment(a.closingDate).format("x") : 0) -
							(b.closingDate ? moment(b.closingDate).format("x") : 0),
					},
					{
						title: "Purchase Date",
						dataIndex: "purchaseDate",
						key: "purchaseDate",
						scopedSlots: { customRender: "purchaseDate" },
						sorter: (a, b) =>
							moment(a.purchaseDate).format("x") -
							moment(b.purchaseDate).format("x"),
					},
					{
						title: "Purchaser Name(s)",
						dataIndex: "purchaseName",
						key: "purchaseName",
						sorter: (a, b) =>
							a.purchaseName < b.purchaseName
								? -1
								: a.purchaseName > b.purchaseName
									? 1
									: 0,
					},
					{
						title: "Sales owner(s)",
						dataIndex: "salesOwners",
						key: "salesOwners",
						sorter: (a, b) =>
							a.salesOwners < b.salesOwners
								? -1
								: a.salesOwners > b.salesOwners
									? 1
									: 0,
					},
					{
						title: "Price per Sq. Ft.",
						dataIndex: "pricePerSqFt",
						key: "pricePerSqFt",
						scopedSlots: { customRender: "pricePerSqFt" },
						sorter: (a, b) => a.pricePerSqFt - b.pricePerSqFt,
					},
					{
						title: "Purchase price",
						dataIndex: "purchasePrice",
						key: "purchasePrice",
						scopedSlots: { customRender: "purchasePrice" },
						sorter: (a, b) => a.purchasePrice - b.purchasePrice,
					},
				]
			);
			return columns;
		},

		filename() {
			return `Sale Report(${moment(this.currentStartDate).format(
				"ll"
			)} - ${moment(this.currentEndDate).format("ll")}${this.compareStartDate && this.compareEndDate
				? " vs " +
				moment(this.compareStartDate).format("ll") +
				" - " +
				moment(this.compareEndDate).format("ll")
				: ""
				})`;
		},

		salesRecords() {
			let records = [...this.records.sales]
			return records.map((s) => {
				return {
					...s,
					offerDate: s.offerDate && formatDate(s.offerDate) || "",
					closingDate: s.closingDate && formatDate(s.closingDate) || "",
					purchaseDate: s.purchaseDate && formatDate(s.purchaseDate) || "",
				}
			})
		}
	},

	created() { },

	methods: {
		formatDate,

		getCSV() {
			if (this.salesRecords.length) {
				let csv = Object.keys(this.salesRecords[0]).join(",");
				csv += "\n";

				this.salesRecords.forEach((row) => {
					csv += Object.values(row)
						.map((r) => {
							if (typeof r === "string") {
								return r.replaceAll(", ", " & ");
							}
							return r;
						})
						.join(",");
					csv += "\n";
				});

				const anchor = document.createElement("a");
				anchor.href =
					"data:text/csv;charset=utf-8," + encodeURIComponent(csv);
				anchor.target = "_blank";
				anchor.download = `${this.filename}.csv`;
				anchor.click();
			} else {
				this.$message.info("No records found!");
			}
		},
	},
};
</script>

<style>
</style>
